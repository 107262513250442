<template>
    <div>
      <article>
        <section>
          <div class="country_dialogs">
            <el-dialog
              :title="`Update Country`"
              :visible.sync="isEditCountryModalVisible"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
              <div v-loading="submitting">
                <el-form
                  :model="countryForm"
                  :rules="rules"
                  label-position="top"
                  ref="countryForm"
                  class="demo-countryForm2"
                >
                 
                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Country Name" prop="countryName">
                      <el-input v-model="countryForm.countryName"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <el-form-item label="Country Title" prop="countryTitle">
                      <el-input v-model="countryForm.countryTitle"></el-input>
                    </el-form-item>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item label="country Brief Description" prop="countryBriefDescription">
                    <editor
                      api-key="e5hm05oesjxxiuelp6g7fd8y2rncj6tqnuzkppcogbnf7ra3"
                      :init="{
                        height: 300,
                        menubar: false,
                        plugins: [
                          'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
                          'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                          'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
                        ],
                        toolbar:
                          'undo redo | casechange blocks | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlst checklist outdent indent | removeformat | a11ycheck code table help'
                      }"
                      placeholder="Country Brief Description ..."
                      v-model="countryForm.countryBriefDescription"
                    />
                    </el-form-item>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item label="Country Overview" prop="countryOverview">
                    <editor
                      api-key="e5hm05oesjxxiuelp6g7fd8y2rncj6tqnuzkppcogbnf7ra3"
                      :init="{
                        height: 300,
                        menubar: false,
                        plugins: [
                          'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
                          'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                          'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
                        ],
                        toolbar:
                          'undo redo | casechange blocks | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlst checklist outdent indent | removeformat | a11ycheck code table help'
                      }"
                      placeholder="Country Overview ..."
                      v-model="countryForm.countryOverview"
                    />
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <el-form-item label="Best Time To Visit" prop="bestTimeToVisit">
                    <editor
                      api-key="e5hm05oesjxxiuelp6g7fd8y2rncj6tqnuzkppcogbnf7ra3"
                      :init="{
                        height: 300,
                        menubar: false,
                        plugins: [
                          'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
                          'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                          'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
                        ],
                        toolbar:
                          'undo redo | casechange blocks | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlst checklist outdent indent | removeformat | a11ycheck code table help'
                      }"
                      placeholder="Best Time To Visit ..."
                      v-model="countryForm.bestTimeToVisit"
                    />
                    </el-form-item>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item label="Tour Ideas Brief Description" prop="toursBriefDescription">
                    <editor
                      api-key="e5hm05oesjxxiuelp6g7fd8y2rncj6tqnuzkppcogbnf7ra3"
                      :init="{
                        height: 300,
                        menubar: false,
                        plugins: [
                          'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
                          'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                          'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
                        ],
                        toolbar:
                          'undo redo | casechange blocks | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlst checklist outdent indent | removeformat | a11ycheck code table help'
                      }"
                      placeholder="Tour Ideas Brief Description ..."
                      v-model="countryForm.toursBriefDescription"
                    />
                    </el-form-item>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item label="Accomodation Brief Description" prop="accomodationBriefDescription">
                    <editor
                      api-key="e5hm05oesjxxiuelp6g7fd8y2rncj6tqnuzkppcogbnf7ra3"
                      :init="{
                        height: 300,
                        menubar: false,
                        plugins: [
                          'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
                          'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                          'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
                        ],
                        toolbar:
                          'undo redo | casechange blocks | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlst checklist outdent indent | removeformat | a11ycheck code table help'
                      }"
                      placeholder="Accomodation Brief Description ..."
                      v-model="countryForm.accomodationBriefDescription"
                    />
                    </el-form-item>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item label="Country's Highlights/Experiences Brief Description" prop="highlightsBriefDescription">
                    <editor
                      api-key="e5hm05oesjxxiuelp6g7fd8y2rncj6tqnuzkppcogbnf7ra3"
                      :init="{
                        height: 300,
                        menubar: false,
                        plugins: [
                          'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
                          'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                          'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
                        ],
                        toolbar:
                          'undo redo | casechange blocks | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlst checklist outdent indent | removeformat | a11ycheck code table help'
                      }"
                      placeholder="Country's Highlights/Experiences Brief Description ..."
                      v-model="countryForm.highlightsBriefDescription"
                    />
                    </el-form-item>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item label="A Country's Places To Visit Brief Description" prop="placesToVisitBriefDescription">
                    <editor
                      api-key="e5hm05oesjxxiuelp6g7fd8y2rncj6tqnuzkppcogbnf7ra3"
                      :init="{
                        height: 300,
                        menubar: false,
                        plugins: [
                          'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
                          'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                          'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
                        ],
                        toolbar:
                          'undo redo | casechange blocks | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlst checklist outdent indent | removeformat | a11ycheck code table help'
                      }"
                      placeholder="A Country's Places To Visit Brief Description ..."
                      v-model="countryForm.placesToVisitBriefDescription"
                    />
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Country Photo">
                      <el-upload class="upload-demo" drag action="''" :on-change="handleCountryPhotoPreview"
                        :on-remove="handleCountryPhotoRemove" :file-list="countryPhotoFileLists" :auto-upload="false"
                        :multiple="false">
                        <div v-if="!isUploadingFile">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                          </div>
                        </div>
                        <div v-else>
                          <el-progress v-if="imageUploadingProgress != 100" type="circle" :color="progressColors"
                            :percentage="imageUploadingProgress" :width="80" :stroke-width="4"></el-progress>
                          <el-progress v-else type="circle" :percentage="100" status="success" :width="80"
                            :stroke-width="4"></el-progress>
                          <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                            {{
                              imageUploadingProgress == 100
                              ? "Uploaded"
                              : "Uploading..."
                            }}
                          </div>
                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                      </el-upload>
                    </el-form-item>
                  </div>
                </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button
                      style="background-color: #083628; color: white;"
                      @click="editCountry('countryForm')"
                    > Save Country Changes</el-button>
                  </el-form-item>
                </el-form>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2024 Bakyala Safaris, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!--v-model="countryForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
  import Editor from "@tinymce/tinymce-vue";
  export default {
    components: {
      editor: Editor,
    },
    data() {
      return {
        loading: false,
        isLoading: false,
        
      selectedCountryPhotoFile: null,
      isUploadingFile: false,
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      countryPhotoFileLists: [],
  
        isEditCountryModalVisible: false,
        loadingError: false,
        submitting: false,
        countryForm: {
          countryName: "",
          countryTitle: "",
          countryBriefDescription: "",
          countryOverview: "",
          bestTimeToVisit: "",
          toursBriefDescription: "",
          accomodationBriefDescription: "",
          highlightsBriefDescription: "",
          placesToVisitBriefDescription: "",
        },
  
        rules: {
          countryName: [
            {
              required: true,
              message: "Country Name is required",
              trigger: "blur",
            },
          ],
          countryOverview: [
            {
              required: true,
              message: "Country Overview is required",
              trigger: "blur",
            },
          ],
          countryTitle:  [
            {
              required: true,
              message: "Country Title is required",
              trigger: "blur",
            },
          ],
          countryBriefDescription:  [
            {
              required: true,
              message: "Country Brief Description is required",
              trigger: "blur",
            },
          ],
          bestTimeToVisit:  [
            {
              required: true,
              message: "Best Time To Visit is required",
              trigger: "blur",
            },
          ],
          toursBriefDescription:  [
            {
              required: true,
              message: "Tours Brief Description is required",
              trigger: "blur",
            },
          ],
          accomodationBriefDescription:  [
            {
              required: true,
              message: "Accomodation Brief Description is required",
              trigger: "blur",
            },
          ],
          highlightsBriefDescription:  [
            {
              required: true,
              message: "Highlights Brief Description is required",
              trigger: "blur",
            },
          ],
          placesToVisitBriefDescription:  [
            {
              required: true,
              message: "Places To Visit Brief Description is required",
              trigger: "blur",
            },
          ],
          
        },
      };
    },
  
    props: {
      showCountryEditDialog: {
        required: true,
        type: Boolean,
      },
      countryData: {
        required: true,
        type: Object,
      },
    },
  
    watch: {
      showCountryEditDialog() {
        if (this.showCountryEditDialog === true) {
          this.isEditCountryModalVisible = true;
          this.countryForm.countryName = this.countryData.countryName;
          this.countryForm.countryOverview = this.countryData.countryOverview;
          this.countryForm.countryTitle= this.countryData.countryTitle;
          this.countryForm.countryBriefDescription= this.countryData.countryBriefDescription;
          this.countryForm.bestTimeToVisit= this.countryData.bestTimeToVisit;
          this.countryForm.toursBriefDescription= this.countryData.toursBriefDescription;
          this.countryForm.accomodationBriefDescription= this.countryData.accomodationBriefDescription;
          this.countryForm.highlightsBriefDescription= this.countryData.highlightsBriefDescription;
          this.countryForm.placesToVisitBriefDescription= this.countryData.placesToVisitBriefDescription;
          this.countryForm.countryID = this.countryData.countryID;
          this.selectedCountryPhotoFile = this.countryData.countryFeaturedPhoto;
          this.countryPhotoFileLists = [
            {
              name: "country_image",
              url: "this.countryData.countryFeaturedPhoto",
            },
          ];
        } else {
          this.isEditCountryModalVisible = false;
        }
      },
    },
  
    methods: {
      handleClose(done) {
        done();
        this.$emit("closeEditCountryDialog");
      },
        
      handleCountryPhotoPreview(file) {
        console.log(file.raw);
        this.countryPhotoFileLists = [file];
        this.selectedCountryPhotoFile = file.raw;
      },
      handleCountryPhotoRemove() {
        this.selectedCountryPhotoFile = null;
      },

  
      async editCountry(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              this.isUploadingFile = true;
              const formData = new FormData();
              formData.append("countryID", this.countryForm.countryID);
              formData.append("countryName", this.countryForm.countryName);
              formData.append("countryOverview", this.countryForm.countryOverview);
              formData.append("countryTitle", this.countryForm.countryTitle);
              formData.append("countryBriefDescription", this.countryForm.countryBriefDescription);
              formData.append("bestTimeToVisit", this.countryForm.bestTimeToVisit);
              formData.append("toursBriefDescription", this.countryForm.toursBriefDescription);
              formData.append("accomodationBriefDescription", this.countryForm.accomodationBriefDescription);
              formData.append("highlightsBriefDescription", this.countryForm.highlightsBriefDescription);
              formData.append("placesToVisitBriefDescription", this.countryForm.placesToVisitBriefDescription);
              formData.append("countryFeaturedPhoto", this.selectedCountryPhotoFile);
              let response = await this.$http.patch(`countries`, formData, {
                onUploadProgress: (progressEvent) => {
                  this.imageUploadingProgress = +(
                    (progressEvent.loaded / progressEvent.total) *
                    100
                  ).toFixed(0);
                },
              });
              if (
                response.data.success &&
                response.data.message == "COUNTRY_UPDATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "Country updated successfully",
                  type: "success",
                });
                this.$emit("closeEditCountryDialog");
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to update Country",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style>
  .country_dialogs .el-dialog {
    width: 80%;
  }
  
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .country_dialogs .el-dialog {
      width: 80%;
    }
  }
  
  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .country_dialogs .el-dialog {
      width: 80%;
    }
  }
  
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .country_dialogs .el-dialog {
      width: 80%;
    }
  }
  
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .country_dialogs .el-dialog {
      width: 80%;
    }
  }
  </style>
  